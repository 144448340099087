'use strict';

var section = require('./section');
var app = require('./app');
var modal = require('./modal');
var videoModal = require('./video-modal');
var focalPoint = require('./focal-point');
var objFit = require('./obj-fit');
var lazyImage = require('./lazy-image');
var gallery = require('./gallery');
var blocks = require('./blocks/main');
var utils = require('./utils/main');
var search = require('./search');
var sticky = require('./sticky');
var formWidget = require('./form-widget');
var menu = require('./menu/main');
var ajaxForm = require('./ajax-form');
var cart = require('./cart');
var viewCartLink = require('./view-cart-link');
var promoLink = require('./promo-link');
var addToCart = require('./add-to-cart');
var applyCoupon = require('./apply-coupon');
var removeCoupon = require('./remove-coupon');
var containerLink = require('container-link');
var loginPage = require('./login-page');
var share = require('./share');

var init = function(actionSection, actionItem) {

	console.log('sites app main.js called!!!');

	app.init();
	// Focal point must be inited before lazy image, because we don't want images loading without their focal point being applied
	focalPoint.init();
	objFit.init();
	// Gallery must be inited before lazy image, because we don't want images loading without their focal point being applied, and the gallery module implements it's own focal point instance vs using the app wide instance inited above
	gallery.init();
	lazyImage.init();
	formWidget.init();
	blocks.init();
	modal.init();
	videoModal.init();
	utils.init();
	sticky.init();
	menu.init();

	ajaxForm.init();
	cart.init();
	viewCartLink.init();
	promoLink.init();
	addToCart.init();
	applyCoupon.init();
	removeCoupon.init();
	containerLink.init();
	search.init();
	loginPage.init();
	share.init();

	section.init(actionSection, actionItem);

};

module.exports = {
	init: init
};
