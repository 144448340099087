'use strict';

var urlBuilder = require('./url-builder');
var device = require('./device');
var dom = require('./dom');

var animations = require('./block-animation');

var init = function() {

	device.init();

	animations.init();

};

module.exports = {
	init: init,
	dom: dom,
	getUrl: urlBuilder.get,
	getDeviceState: device.getState,
	wasDeviceUpsized: device.wasUpsized,
	wasDeviceDownsized: device.wasDownsized,
	supports: device.supports
};
